import React, { useState, useEffect } from "react";
import axios from "axios";
import "./index.css";

function App() {
  const [file, setFile] = useState(null);
  const [username, setUsername] = useState("98967");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [logs, setLogs] = useState("");
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState("");
  const [downloadAngles, setDownloadAngles] = useState(false); // Nuevo estado

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCheckboxChange = () => {
    setDownloadAngles(!downloadAngles); // Alternar estado del checkbox
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || !password) {
      setMessage("Por favor, seleccione un archivo y ingrese la contraseña.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("username", username);
    formData.append("senha", password);
    formData.append("downloadAngles", downloadAngles); // Enviar estado del checkbox

    try {
      const response = await axios.post(
        "https://images.leadershoes.com.mx/api/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        setMessage("Las imágenes se están descargando.");
        setIsProcessing(true);
        setStartTime(Date.now());
      } else {
        setMessage("Error: " + response.data.error);
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  const handleGetLogs = async () => {
    try {
      const response = await axios.get(
        "https://images.leadershoes.com.mx/api/logs"
      );
      if (response.data.success) {
        setLogs(response.data.logs);
      } else {
        setMessage("Error al obtener los logs: " + response.data.error);
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  const fetchDownloadedFiles = async () => {
    try {
      const response = await axios.get(
        "https://images.leadershoes.com.mx/api/files"
      );
      if (response.data.success) {
        setDownloadedFiles(response.data.files);
      } else {
        setMessage(
          "Error al obtener la lista de archivos: " + response.data.error
        );
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  const fetchProgress = async () => {
    try {
      const response = await axios.get(
        "https://images.leadershoes.com.mx/api/status"
      );
      const { totalProducts, processedProducts, isProcessing, errorMessage } =
        response.data;
      const progress = (processedProducts / totalProducts) * 100;
      setProgress(progress);

      // Calcular tiempo estimado restante
      const currentTime = Date.now();
      const elapsedTime = (currentTime - startTime) / 1000; // en segundos
      const estimatedTotalTime = (elapsedTime / progress) * 100; // en segundos
      const estimatedRemainingTime = estimatedTotalTime - elapsedTime; // en segundos

      setEstimatedTime(formatTime(estimatedRemainingTime));

      if (errorMessage) {
        setMessage(`Error: ${errorMessage}`);
        setIsProcessing(false);
      } else if (!isProcessing) {
        setIsProcessing(false);
        setMessage("Proceso completado.");
        fetchDownloadedFiles();
      }
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}m ${secs}s`;
  };

  const handleDownloadAll = () => {
    downloadedFiles.forEach((file) => {
      const link = document.createElement("a");
      link.href = `https://images.leadershoes.com.mx/api/download/${file}`;
      link.download = file;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  useEffect(() => {
    fetchDownloadedFiles();
  }, []);

  useEffect(() => {
    let interval;
    if (isProcessing) {
      interval = setInterval(() => {
        fetchProgress();
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isProcessing]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h3 className="text-4xl font-bold mb-8 text-center text-gray-900">
          Descarga de imágenes Beira Rio
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Usuario
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Contraseña
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="file"
            >
              Archivo
            </label>
            <input
              id="file"
              type="file"
              onChange={handleFileChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <input
                type="checkbox"
                checked={downloadAngles}
                onChange={handleCheckboxChange}
                className="mr-2 leading-tight"
              />
              Descargar Ángulos
            </label>
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            Subir y Descargar Imágenes
          </button>
          {message && (
            <p className="mt-4 text-red-500 text-center">{message}</p>
          )}
          {isProcessing && (
            <div className="mt-4">
              <p className="text-center text-gray-700">
                Procesando: {progress.toFixed(2)}%
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <p className="text-center text-gray-700 mt-2">
                Tiempo estimado restante: {estimatedTime}
              </p>
            </div>
          )}
        </form>
        <button
          onClick={handleGetLogs}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-4"
        >
          Ver Logs
        </button>
        {logs && (
          <pre className="mt-4 p-4 bg-gray-100 rounded text-xs overflow-auto max-h-64">
            {logs}
          </pre>
        )}
        {downloadedFiles.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-bold mb-2">Archivos Descargados:</h4>
            <ul className="list-disc pl-5">
              {downloadedFiles.map((file, index) => (
                <li key={index}>
                  <a
                    href={`https://images.leadershoes.com.mx/api/download/${file}`}
                    download
                    className="text-blue-500 hover:underline"
                  >
                    {file}
                  </a>
                </li>
              ))}
            </ul>
            <button
              onClick={handleDownloadAll}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-4"
            >
              Descargar Todos
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
